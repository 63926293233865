import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundShape from "../components/backgroundShape"
import Container from "../components/container"
import Layout from "../components/layout"
import Head from "../components/head"
import Paragraph from "../components/new/paragraph/paragraph"
import Grid from "../components/new/grid/grid"
import FeatureCategoryCard from "../components/new/featureCategoryCard/featureCategoryCard"

const FeaturePage = () => {
  const data = useStaticQuery(graphql`
        query {
          allContentfulFeatureCategory {
            edges {
              node {
                title
                slug
                icon
                description
                order
              }
            }
          }
        }

  `)


   return (
    <Layout navbar="transparent">
          <Head title="A complete set of features to enable you to scale faster." description="Our cloud based software helps you connect with multiple vendors and manage all your marketplace operations from a single platform."/>
          <BackgroundShape style={{shape: "clip-md", color: "bg-blog-lg", particles: false}}/>
          <Container style={{ classes: "containerOn hero container" }}>
            <div className="d-flex justify-content-between align-items-center">
              <div className="header w-50 p-0">
                <h1 className="header__banner-title">Composable Technology for Next-Generation Marketplaces</h1>
                <p className="header__banner-subtitle">
                The Onport Platform  allows your team to focus on growing the business instead of managing complex processes and resource-intensive  workflows                </p>
              </div>
              <div className="w-50 desktop-only text-center">
              <img src={'/images/features-header.svg'} style={{height:'500px'}} data-sal='slide-left' data-sal-duration='600'/>
              </div>
            </div>
          </Container>
          <Container style={{ classes: "container" }}>
          <Paragraph text='The <b>Onport</b> Platform is fully flexible for those seeking an out-of-the-box solution with pre-built integrations, or those who require a fully customizable implementation through an open source API with <b>300+</b> endpoints.<br/><br/>It offers a wide range of features to manage your business from one single place based on a platform supporting 5 main areas:' style='text-center xl w-80 mx-auto my-5 pb-5' anim='slide-up'/>
            <Grid style='grid-4 my-5'>
              {data.allContentfulFeatureCategory.edges.sort((a, b) => a.node.order - b.node.order).map((edge) => {
                    return(
                      <FeatureCategoryCard title={edge.node.title} slug={edge.node.slug} description={edge.node.description}/>
                    )})
              }
            </Grid>
          </Container>

      </Layout>
    )
}



export default FeaturePage
